<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Product Images</h1>
            </div>
        </div>

        <div class="row ">

            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <div>
                            <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                {{FilterProductTypeId==0?'== All Types ==':mapProductTypes[FilterProductTypeId].Description}}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li class="dropdown-item"
                                    v-for="item in mapProductTypes" :key="item.Id"
                                    @click="doViewByFilter(item.Id)">
                                    <a href="javascript:;">{{item.Id!=0?item.Description:'== All Types =='}}</a>
                                </li>
                            </ul>
                            <button class="btn btn-primary" type="button" @click="$router.push('/ProductPDF')">
                                PDF Page
                            </button>
                                
                            
                        </div>
                        <div class="mt-3">
                            <table class="table table-hover table-bordered" id="datatable">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th> </th>
                                        <th>Name</th>
                                        <th>Product Type</th>

                                        <th>Price (No VAT)</th>
                                        <th>Inventory <br /> (Tồn Kho)</th>
                                        <th>UpdatedBy</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in dataByFilter" :key="item.Code" :style="{'background-color':item.Stock<=0?'#ff000021 !important':'inherit'}">
                                        <td @click="ViewHistory(item.Code,item.Name)">
                                            <a href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                               title="View History">
                                                {{item.Code}}
                                            </a>
                                        </td>
                                        <td @click="EditDetail(item)">
                                            <img :src="item.HamperImage" style="max-height:100px;max-width:100px" v-if="item.HamperImage!=''" />
                                        </td>
                                        <td @click="EditDetail(item)" v-if="checkFullAcl('ProductDetail/Edit')">
                                            <a href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                               title="View History">
                                                {{item.Name}}
                                            </a>
                                        </td>

                                        <td class="">
                                            <select class="select mb-1" :id="'ProductTypeId_'+item.Id" @change="ChangeType(item)">
                                                <option v-for="prodtype in ProductTypes" :key="prodtype.Id" :value="prodtype.Id" :selected="item.ProductTypeId==prodtype.Id?'true':null">{{prodtype.Description}}</option>
                                            </select>
                                        </td>
                                        <!--<td>{{formatMoney(item.ProductTypeId!=undefined?item.Price*mapProductTypes[item.ProductTypeId].MultiValue:item.Price)}}</td>-->
                                        <td>{{formatMoney(item.Price)}}</td>
                                        
                                        <td>{{item.Stock}}</td>
                                        <td>{{item.UpdatedBy}}</td>
                                        <td>{{formatDate(item.Updated) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>



        <MDBModal id="editProductDetailModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showProductDetail"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle v-html="editTitle"></MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>

                <!--<MDBRow>
                    <MDBCol md="4">
                        <MDBInput label="Width (cm)"
                                  id="HamperSizeX"
                                  v-model="editData.HamperSizeX"
                                  type="number"
                                  step="1"
                                  invalidFeedback="Please provide a image"
                                  validFeedback="Looks good!" />
                    </MDBCol>
                    <MDBCol md="4">
                        <MDBInput label="Height  (cm)"
                                  id="HamperSizeY"
                                  v-model="editData.HamperSizeY"
                                  type="number"
                                  step="1"
                                  invalidFeedback="Please provide a image"
                                  validFeedback="Looks good!" />
                    </MDBCol>
                </MDBRow>-->
                <MDBRow class="mt-3">
                    <MDBCol md="4">
                            <MDBInput label="Original Price (NO VAT)"
                                      id="Price"
                                      type="number"
                                      step="1000"
                                      v-model="editData.Price"
                                      invalidFeedback="Please provide a image"
                                      validFeedback="Looks good!" />
                        </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3">
                    <MDBCol md="4">
                        <button type="button" class="btn btn-primary btn-with-icon" @click="SelectFileUpload(event)"><i class="ion-image"></i>Select Hamper Image</button>
                        <div class="mt-3" id="uploadFileInfo" style="text-align:center">
                            <img :src="editData.HamperImage" style="max-height:500px;max-width:500px" v-if="editData.HamperImage!=''" />
                        </div>
                    </MDBCol>
                    <MDBCol md="6">
                        <MDBCheckbox v-model="editData.Exclude" label="Remove from Hamper" />
                    </MDBCol>
                </MDBRow>
                <!--<MDBRow>
        <MDBCol md="12">
            <button type="button" class="btn btn-primary btn-with-icon" @click="SelectFilesUpload(event)"><i class="ion-images"></i>Select Many Images</button>
            <div class="mt-3" id="uploadFilesInfo">

            </div>
        </MDBCol>
    </MDBRow>-->


            </MDBModalBody>
            <MDBModalFooter>
                <button type="submit" class="btn btn-success" @click="SaveEdit">
                    Save
                </button>
                <button type="button" class="btn btn-danger" @click="showProductDetail=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>


    </div>
</template>
<style>
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: white !important;
    }

.editable-click, a.editable-click {
    border-bottom: none;
}
  
</style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    //import "datatables.net-buttons/js/dataTables.buttons.js"
    //import "datatables.net-buttons/js/buttons.colVis.js"
    //import "datatables.net-buttons/js/buttons.flash.js"
    //import "datatables.net-buttons/js/buttons.html5.js"
    //import "datatables.net-buttons/js/buttons.print.js"
    import $ from 'jquery';
    import 'datatables.net-responsive'

    
    import {
        //MDBBtn,
        MDBModal,
        MDBModalBody,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalFooter,
        MDBCol, MDBRow,
        MDBInput,
        MDBCheckbox
    } from 'mdb-vue-ui-kit';


    import c from '@/mylib/common'
    import { mapState, mapActions } from 'vuex'



    export default {
        name: `ProductDetail`,
        components: {
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
            MDBCol, MDBRow,
            MDBInput,
            MDBCheckbox
        },
        data: function () {
            return {
                editTitle:'',
                editData: {},
                data: [],                
            dataByFilter:[],
                ProductTypes: [],
                FilterProductTypeId: 0,
                mapProductTypes: {},
                dataTable: null,
                showProductDetail: false,

            }
        },       

        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',
                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();
                
            },
            formatDate(dstr, notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate(d, notime);
            },
            formatMoney(money, symbol, decCount) {
                symbol = symbol || '';
                decCount = decCount || 0;

                const rs = c.formatMoney(money, symbol, decCount);
                return rs;
            },
            ViewHistory(code, name) {
                console.log(code);
                if (this.checkFullAcl('Product/ViewHistory'))
                    this.$store.dispatch("view/viewHistory", { code: code,name:name });
            },
            validPrice() {
                return this.validNumField(this.editData.Price, "Price", 1000);
            },
            validSizeX() {
                return this.validNumField(this.editData.HamperSizeX, "HamperSizeX", 1);
            },
            validSizeY() {
                return this.validNumField(this.editData.HamperSizeY, "HamperSizeY", 1);
            },
            validNumField(value, id, minVal, maxVal) {
                if (!id || id == '') return;
                let error = "";
                if (isNaN(value))
                    error = "Please input number.";
                else if (minVal != undefined && value < minVal)
                    error = "Must greater than or equal " + minVal;
                else if (maxVal != undefined && value > maxVal)
                    error = "Must less than or equal " + maxVal;
                //return value
                if (error == '') {
                    if ($('#' + id).length > 0) {
                        $('#' + id).removeClass('is-invalid');
                        $('#' + id).addClass('is-valid');
                    }
                    return true;
                } else {
                    if ($('#' + id).length > 0) {
                        $('#' + id + '~.invalid-feedback')[0].innerHTML = error;
                        $('#' + id).addClass('is-invalid');
                        $('#' + id).removeClass('is-valid');
                    }
                    $('#' + id).focus();
                    return false;
                }
            },
            validTextField(value, id, required, regex, regexMessage, minlen, maxlen, NameExisted, editedId) {
                if (!id || id == '') return;
                let error = "";
                minlen = minlen || 0;
                maxlen = maxlen || 1000;
                editedId = editedId || 0;
                const checkedvalue = value.trim();
                const isCheckRequire = required || (!required && checkedvalue.length > 0);

                if (required && checkedvalue == '') {
                    error = "Please input this field!";
                }
                //check name existed
                else if (NameExisted != null && NameExisted != undefined &&
                    (NameExisted[checkedvalue] != undefined && editedId != NameExisted[checkedvalue] || (Array.isArray(NameExisted) && NameExisted.includes(checkedvalue)))
                ) {
                    error = id + " is duplicated!";
                }
                //check pattern
                else if (isCheckRequire && regex != null && regex != undefined && !regex.test(checkedvalue))
                    error = regexMessage || `Invalid ${id}!`;
                //check len

                else if (isCheckRequire && (checkedvalue.length < minlen)) {
                    error = `Please input at least ${minlen} characters!`;
                }
                else if (checkedvalue.length > maxlen) {
                    error = `Please do not exceed ${maxlen} characters!`;
                }

                //return value
                if (error == '') {
                    if ($('#' + id).length > 0) {
                        $('#' + id).removeClass('is-invalid');
                        $('#' + id).addClass('is-valid');
                    }
                    return true;
                } else {
                    if ($('#' + id).length > 0) {
                        $('#' + id + '~.invalid-feedback')[0].innerHTML = error;
                        $('#' + id).addClass('is-invalid');
                        $('#' + id).removeClass('is-valid');
                    }
                    $('#' + id).focus();
                    return false;
                }
            },
            EditDetail(item) {
                if (!this.checkFullAcl('ProductDetail/Edit')) return;
                this.editData = {};
                this.FileUpload = {};
                this.editData = JSON.parse(JSON.stringify(item));
                this.editTitle = item.Name;
                this.editData["HamperSizeX"] = this.editData["HamperSizeX"] + '';
                this.editData["HamperSizeY"] = this.editData["HamperSizeY"] + '';
                this.editData["Price"] = this.editData["Price"] + '';
                this.editData["Exclude"] = item.Exclude;
                this.showProductDetail = true;
            },
            doViewByFilter(typeId, isFirstLoad) {                
                if (isFirstLoad == undefined) isFirstLoad = false;
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }
                
                this.FilterProductTypeId = typeId;
                this.dataByFilter = {};       

                Object.keys(this.data).forEach(key => {
                    if (this.data[key].ProductTypeId == typeId || typeId == 0) {
                        this.dataByFilter[this.data[key].Id] = this.data[key];
                    }
                });

                setTimeout(this.doTable, isFirstLoad?300:200);
            },
            SelectFileUpload() {
                var el = window._protected_reference = document.createElement("INPUT");
                el.type = "file";
                //el.accept = ".jpg,.jpeg,.png";
                el.accept = "image/*";
                //el.multiple = "multiple"; // remove to have a single file selection

                // (cancel will not trigger 'change')
                var _this = this;
                el.addEventListener('change', function () {
                    // access el.files[] to do something with it (test its length!)

                    // add first image, if available
                    if (el.files.length) {
                        const fileSize = el.files[0].size / 1024 / 1024; // in MiB
                        if (fileSize > 2) {
                            this.showToast({ isShow: true, title: '', content:"File size exceeds 2 MB", type: 'error' });
                            return;
                        }
                        _this.FileUpload = el.files[0];
                        //document.getElementById('uploadFileInfo').innerHTML = _this.FileUpload.name;
                        document.getElementById('uploadFileInfo').innerHTML = `<img style="max-height:500px;max-width:500px" src="${URL.createObjectURL(el.files[0])}" />`
                    }


                    //// test some async handling
                    //new Promise(function (resolve) {
                    //    setTimeout(function () { console.log(el.files); resolve(); }, 1000);
                    //})
                    //    .then(function () {
                    //        // clear / free reference
                    //        el = window._protected_reference = undefined;
                    //    });

                });
                el.click();

            },
            async SaveEdit() {
            
                let isValidImage = true;
                if (this.FileUpload == null && this.editData.Id == 0) {
                    this.showModal({ isShow: true, title: 'Error', content: "Please select a Hamper Image for this product", type: 'error' });
                    isValidImage = false;
                }
                let isValidPrice = this.validPrice();
                if (!this.validSizeX()) this.editData.HamperSizeX=1;
                if (!this.validSizeY()) this.editData.HamperSizeY = 1;
                let isValidForm = isValidPrice;
                if (!isValidForm && isValidImage) {
                    this.showToast({ isShow: true, title: '', content: "Form is invalid!", type: 'error' });                    
                }
                if (!isValidForm || !isValidImage) return;

                this.loading(true);

                const rs = await this.call({
                    Action: 'ProductDetail/Save', Object: {
                        Code: this.editData.Code,
                        HamperSizeX: this.editData.HamperSizeX,
                        HamperSizeY: this.editData.HamperSizeY,
                        Price: this.editData.Price,
                        Exclude: this.editData.Exclude,
                    }, Files: this.FileUpload
                })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    
                this.dataByFilter[this.editData.Id].HamperSizeX = this.editData.HamperSizeX;
                this.dataByFilter[this.editData.Id].HamperSizeY = this.editData.HamperSizeY;
                this.dataByFilter[this.editData.Id].Price = this.editData.Price;
                    this.dataByFilter[this.editData.Id].Exclude = this.editData.Exclude;    
                    this.dataByFilter[this.editData.Id].HamperImage = rs.Data.HamperImage;    
                    this.showProductDetail = false;
                }
            },
        async ChangeType(item) {
            var productTypeId = $('#ProductTypeId_' + item.Id).val();
            if (productTypeId == undefined || productTypeId == null|| productTypeId == 0) {
                this.showModal({ isShow: true, title: 'Error', content: "Please select a Type this product", type: 'error' });
                return;
            }
            
            this.loading(true);

            const rs = await this.call({
                Action: 'ProductDetail/SaveProductType', Object: {
                    Code: item.Code,
                    ProductTypeId:  productTypeId
                }
            })
            this.loading(false);
            this.error = rs.Error;
            if (rs.Error != "") {
                //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                $('#ProductTypeId_' + item.Id).val(item.ProductTypeId==null?0:item.ProductTypeId);             
            } else {
                this.dataByFilter[item.Id].ProductTypeId = productTypeId;
            }
        },
            async getAll() {
                this.loading(true);
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }
                const rs = await this.call({ Action: 'ProductDetail/View' })
                
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    this.loading(false);
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }

                } else {
                    this.data = {};
                    Object.keys(rs.Data.prodds).forEach(code => {                        
                        this.data[code] = rs.Data.prodds[code];
                        if (rs.Data.prods[code] != undefined) {
                            this.data[code].Stock = rs.Data.prods[code].Remain;
                            this.data[code].Name = rs.Data.prods[code].Name;
                        }
                    });

                    this.ProductTypes = rs.Data.ProductTypes;
                    Object.keys(this.ProductTypes).forEach(i => {
                        let item = this.ProductTypes[i];
                        this.mapProductTypes[item.Id] = item;
                    });
                    this.doViewByFilter(0, true);
                    
                }
            },
            doTable() {
                const _this = this;
                
                _this.dataTable = $('#datatable').DataTable({
                    responsive: true,
                'columnDefs': [         // see https://datatables.net/reference/option/columns.searchable
                    {
                        'searchable': false,
                        'targets': [3],
                    },
                    // {
                    //     'targets': [4],
                    //     visible: false
                    // },
                ]
                    
                });
                this.loading(false);
            },            
            
        },
        
        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
            
           
            
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                console.log("user index newVal", newVal);
                if (newVal) {
                    this.start();
                }
            },

        }
    };
</script>
